import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Leistungen } from './components/Leistungen';
import { Ueber } from './components/Ueber';
import { Wartungstarttext } from './components/Wartungstarttext';

import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path='/leistungen' component={Leistungen} />
                <Route path='/ueber' component={Ueber} />
                <Route path='/wartungtext' component={Wartungstarttext} />
            </Layout>
        );
    }
}
