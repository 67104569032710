import React, { Component } from 'react';

export class Impressum extends Component {
    static displayName = Impressum.name;

    render() {
        return (
            <div>
                <h3>Webseitenbetreiber</h3>
                <div className="container">
                    <div className="row">
                        <div className="col-sm">
                            Dr. Martin Marady<br />
                                            Burkhardgasse 1<br />
                                            A-2700 Wiener Neustadt<br />
                                            Telefon: <a href="tel:+43262223344">+43(2622)23344</a>
                        </div>
                        <div className="col-sm">
                            <a href="http://firmen.wko.at/Web/DetailsKontakt.aspx?FirmaID=defc342d-801e-4172-8762-33e3e4574197&Suchbegriff=Marady&StandortID=0&CategoryID=0&Page=1" target="_blank" rel="noopener noreferrer"><img src={require('../images/ecg.gif')} alt="Informationspflicht" /></a><br />
                            <a href={require('../data/DSGVO.pdf')} target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            <div>
                                <br />
                                <h2>Haftungsausschluss</h2>
                                                Der Autor nimmt in Hinblick auf die technischen Eigenschaften des Internet keine Gewähr für die Qualität, Korrektheit, Aktualität oder Vollständigkeit, der im Internet zur Verfügung gestellten Informationen.<br /><br />

Jede Haftung für Schäden materieller oder ideeller Art, unabhängig von der Ursache, die aus der Benutzung oder Nichtverfügbarkeit der Daten und Informationen dieser Homepage entwickelt wird ausgeschlossen.<br /><br />

Der Inhaber dieser Seiten behält sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne separate Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.<br /><br />

Der gesamte Inhalt dieser Website ist urheberrechtlich geschützt. Die Informationen sind nur für die persönliche Verwendung bestimmt. Jeglicher Missbrauch, insbesondere die Speicherung und Vervielfältigung, sowie die Weitergabe an Dritte sind untersagt.<br /><br />

Copyright 2008-2020<br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
