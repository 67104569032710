import React, { Component } from 'react';
import { Ordination } from './Ordination';
import { Impressum } from './Impressum';
import { Team } from './Team';
import { Kontakt } from './Kontakt';

export class Ueber extends Component {
    static displayName = Ueber.name;


    render() {
        return (
            <div >
                <div className="card">
                    <div className="card-body">
                        <h1 className="card-title">Über uns</h1>
                        <h2 className="card-title">Ordination</h2>
                        <div className="card-text">
                            <Ordination />
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h2 className="card-title">Team</h2>
                        <div className="card-text">
                            <Team />
                        </div>
                    </div>
                </div>
                {/*<div className="card">
                    <div className="card-body">
                        <h2 className="card-title">Kontakt</h2>
                        <div className="card-text">
                            <Kontakt />
                        </div>
                    </div>
                </div>*/}
                <div className="card">
                    <div className="card-body">
                        <h2 className="card-title" >Impressum</h2>
                        <div className="card-text">
                            <Impressum />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
{
    /*
      <div className='accordion' id="accordionExample">
                        <div className="card">
                            <div className="card-header" id="headingOne">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" itemType="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <h2>Ordination</h2>
                                    </button>
                                </h2>
                            </div>
    
                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div className="card-body">
    
                                    <Ordination />
    
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingTwo">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        <h2>Team</h2>
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div className="card-body">
    
                                    <Team />
    
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingThree">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        <h2>Kontakt</h2>
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body">
    
                                    <Kontakt />
    
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingFour">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        <h2>Impressum</h2>
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div className="card-body">
    
                                    <Impressum />
    
                                </div>
                            </div>
                        </div>
                    </div>
     */
}