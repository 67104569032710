import React, { Component } from 'react';
import { Kinder } from './Kinder';

export class Leistungen extends Component {
    static displayName = Leistungen.name;


    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-body">
                        <h1 className="card-title">Leistungen</h1>
                        <h2 className="card-title">Kassenleistungen</h2>
                        <div className="card-text">
                            <div className="row">
                                <div className="col-md-8">
                                    <div>
                                        Vorsorge, Erkennung und Behandlung aller Erkrankungen des Auges:<br />
                                        <ul>
                                            <li>Grauer Star ( Trübung der Augenlinse)</li>
                                            <li>Grüner Star ( Faserverlust des Sehnervs )</li>
                                            <li>Altersbedingte Maculadegeneration</li>
                                            <li>Locherkennung in der Netzhaut</li>
                                            <li>Entzündungen des äußeren und inneren Auges</li>
                                            <li>Behandlung von Fehlstellungen und Bewegungsstörungen des Auges</li>
                                            <li><a href='leistungen/#Kinderaugenheilkunde'>Sehschule speziell für Kinder</a></li>
                                            <li>Anpassung von allen Brillentypen</li>
                                            <li>Abgabe von <a href='leistungen/#Kontaktlinsen'>Kontaktlinsen</a> zu den gültigen Bedingungen ihrer Krankenkasse</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-md-4">

                                    <div id="carouselKassaCaptions" className="carousel slide shadow" data-ride="carousel">
                                        <ol className="carousel-indicators">
                                            <li data-target="#carouselKassaCaptions" data-slide-to="0" className="active"></li>
                                            <li data-target="#carouselKassaCaptions" data-slide-to="1"></li>
                                            <li data-target="#carouselKassaCaptions" data-slide-to="2"></li>
                                            <li data-target="#carouselKassaCaptions" data-slide-to="3"></li>
                                        </ol>
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <img src={require('../images/KassenLeistung01.jpg')} className="d-block w-100 rounded teamfoto" alt="" />
                                                <div className="carousel-caption d-none d-md-block">
                                                </div>
                                            </div>
                                            <div className="carousel-item">
                                                <img src={require('../images/KassenLeistung02.jpg')} className="d-block w-100 rounded teamfoto" alt="" />
                                                <div className="carousel-caption d-none d-md-block">
                                                </div>
                                            </div>
                                            <div className="carousel-item">
                                                <img src={require('../images/KassenLeistung03.jpg')} className="d-block w-100 rounded teamfoto" alt="" />
                                                <div className="carousel-caption d-none d-md-block">
                                                </div>
                                            </div>
                                            <div className="carousel-item">
                                                <img src={require('../images/KassenLeistung04.jpg')} className="d-block w-100 rounded teamfoto" alt="" />
                                                <div className="carousel-caption d-none d-md-block">
                                                </div>
                                            </div>
                                        </div>
                                        <a className="carousel-control-prev" href="#carouselKassaCaptions" role="button" data-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                        <a className="carousel-control-next" href="#carouselKassaCaptions" role="button" data-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Kinder />

                <div className="card">
                    <div className="card-body">
                        <h2 className="card-title">Privatleistungen</h2>
                        <div className="card-text">
                            <div className="row">
                                <div className="col-md-8">
                                    <a href='leistungen/#OCT'>Vorsorgemedizin durch Spectralis / Bluepeak OCT bei</a>:
                                    <ul>
                                        <li>Grünem Star (Glaukom)</li>
                                        <li>Maculadegeneration (AMD)</li>
                                        <li>Diabetikern</li>
                                    </ul>

                                    Anpassung und Abgabe von Kontaktlinsen<br />
                                    Abgabe von Kontaktlinsenpflege- und Kontaktlinsenbenetzungsmittel<br />

                                    Führerscheinuntersuchungen<br />

                                    Bestätigungen und medizinische Atteste<br />

                                    Benetzungsmittel bei trockenem Auge<br />
                                    Vitaminpräparate zur Prophylaxe bei Maculadegeneration
                                </div>

                                <div className="col-md-4">

                                    <div id="carouselPrivatCaptions" className="carousel slide shadow" data-ride="carousel">
                                        <ol className="carousel-indicators">
                                            <li data-target="#carouselPrivatCaptions" data-slide-to="0" className="active"></li>
                                            <li data-target="#carouselPrivatCaptions" data-slide-to="1"></li>
                                        </ol>
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <img src={require('../images/privatleistung01.jpg')} className="d-block w-100 rounded teamfoto" alt="" />
                                                <div className="carousel-caption d-none d-md-block">
                                                </div>
                                            </div>
                                            <div className="carousel-item">
                                                <img src={require('../images/privatleistung02.jpg')} className="d-block w-100 rounded teamfoto" alt="" />
                                                <div className="carousel-caption d-none d-md-block">
                                                </div>
                                            </div>
                                        </div>
                                        <a className="carousel-control-prev" href="#carouselPrivatCaptions" role="button" data-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                        <a className="carousel-control-next" href="#carouselPrivatCaptions" role="button" data-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h2 className="card-title" id='Kontaktlinsen'>Kontaktlinsen vom Augenarzt</h2>
                        <div className="card-text">
                            <div className="row">
                                <div className="col-md-4">
                                    <div id="carouselLinsenCaptions" className="carousel slide shadow" data-ride="carousel">
                                        <ol className="carousel-indicators">
                                            <li data-target="#carouselLinsenCaptions" data-slide-to="0" className="active"></li>
                                            <li data-target="#carouselLinsenCaptions" data-slide-to="1"></li>
                                            <li data-target="#carouselLinsenCaptions" data-slide-to="2"></li>
                                        </ol>
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <img src={require('../images/kontaktlinsen02.jpg')} className="d-block w-100 rounded teamfoto" alt="" />
                                                <div className="carousel-caption d-none d-md-block">
                                                </div>
                                            </div>
                                            <div className="carousel-item">
                                                <img src={require('../images/kontaktlinsen03.jpg')} className="d-block w-100 rounded teamfoto" alt="" />
                                                <div className="carousel-caption d-none d-md-block">
                                                </div>
                                            </div>
                                            <div className="carousel-item">
                                                <img src={require('../images/kontaktlinsen04.jpg')} className="d-block w-100 rounded teamfoto" alt="" />
                                                <div className="carousel-caption d-none d-md-block">
                                                </div>
                                            </div>

                                        </div>
                                        <a className="carousel-control-prev" href="#carouselLinsenCaptions" role="button" data-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                        <a className="carousel-control-next" href="#carouselLinsenCaptions" role="button" data-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <p>
                                        Nutzen Sie die Möglichkeit Ihre LINSEN vom Augenarzt anpassen und regelmäßig kontrollieren zu lassen.
                                    </p>
                                    <p>
                                        Gemeinsam mit meinen speziell ausgebildeten Assistentinnen führen wir Augenvermessung, Linsenanpassung, Linsenabgabe, Beratung über Handhabung und Linsenhygiene für Sie durch.
                                    </p>
                                    <p>
                                        Bei Anspruch auf Vergütung durch die Krankenkassen bestehen Abrechnungsverträge mit fast allen Versicherungsträgern.
                                    </p>
                                    <p>
                                        Sie erhalten somit alle Leistungen in Zusammenhang mit Ihren Linsen an einem Ort und aus einer Hand.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h2 className="card-title" id='OCT'>Spectralis / Blue Peak OCT</h2>
                        <div className="card-text">
                            <div className="row">
                                <div className="col-md-8">
                                    Das neue OCT Gerät Spectralis / Blue Peak bietet unseren Patienten die neueste Technik für Vorsorgeuntersuchungen berührungslos und schmerzfrei.
                                    Besonders bei Glaukom (Grüner Star), Maculadegeneration (AMD) oder Netzhautschäden bei Diabetes ist die frühe Diagnose wichtig für den sicheren Behandlungserfolg.

                                    Die OCT Unsersuchung ist der Standard in der Strukturdiagnostik der Netzhaut. Strukturen jenseits des Auflösungsvermögens des menschlichen Auges werden sichtbar.
                                </div>
                                <div className="col-md-4">

                                    <div id="carouselOctCaptions" className="carousel slide shadow" data-ride="carousel">
                                        <ol className="carousel-indicators">
                                            <li data-target="#carouselOctCaptions" data-slide-to="0" className="active"></li>
                                            <li data-target="#carouselOctCaptions" data-slide-to="1"></li>
                                            <li data-target="#carouselOctCaptions" data-slide-to="2"></li>
                                        </ol>
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <img src={require('../images/oct01.jpg')} className="d-block w-100 rounded teamfoto" alt="" />
                                                <div className="carousel-caption d-none d-md-block">
                                                </div>
                                            </div>
                                            <div className="carousel-item">
                                                <img src={require('../images/oct02.jpg')} className="d-block w-100 rounded teamfoto" alt="" />
                                                <div className="carousel-caption d-none d-md-block">
                                                </div>
                                            </div>
                                            <div className="carousel-item">
                                                <img src={require('../images/oct03.jpg')} className="d-block w-100 rounded teamfoto" alt="" />
                                                <div className="carousel-caption d-none d-md-block">
                                                </div>
                                            </div>
                                        </div>
                                        <a className="carousel-control-prev" href="#carouselOctCaptions" role="button" data-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                        <a className="carousel-control-next" href="#carouselOctCaptions" role="button" data-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <a href="/ueber">
                            <button type="button" className="btn btn-outline-secondary btn-lg btn-block" >Über uns</button>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
