import React, { Component } from 'react';

export class Kinder extends Component {
    static displayName = Kinder.name;

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <h2 className="card-title" id="Kinderaugenheilkunde">Sehschule Kinderaugenheilkunde</h2>
                    <div className="card-text">
                        <div className="row">
                            <div className="col-md-4">

                                <div id="carouselKinderCaptions" className="carousel slide shadow" data-ride="carousel">
                                    <ol className="carousel-indicators">
                                        <li data-target="#carouselKinderCaptions" data-slide-to="0" className="active"></li>
                                        <li data-target="#carouselKinderCaptions" data-slide-to="1"></li>
                                    </ol>
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <img src={require('../images/Kinder1.jpg')} className="d-block w-100 rounded teamfoto" alt="" />
                                            <div className="carousel-caption d-none d-md-block">
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <img src={require('../images/Kinder2.jpg')} className="d-block w-100 rounded teamfoto" alt="" />
                                            <div className="carousel-caption d-none d-md-block">
                                            </div>
                                        </div>
                                    </div>
                                    <a className="carousel-control-prev" href="#carouselKinderCaptions" role="button" data-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                    <a className="carousel-control-next" href="#carouselKinderCaptions" role="button" data-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <p>
                                    Mir ist die Betreuung von Kindern im Rahmen der Mutter-Kind Pass Untersuchungen mit 12 und 24 Monaten ein besonderes Anliegen.
                                </p>
                                <p>
                                    Bei Augenproblemen von Kindern wie Schielen oder Kopfschmerzen beim Lesen und vieles andere mehr versuchen wir bereits den kleinsten Kindern die beste Hilfe zu ermöglichen.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
