import React, { Component } from 'react';

export class Team extends Component {
    static displayName = Team.name;

    render() {
        return (
            <div className="container" id="Ordination">
                <div className="row">
                    <div className="col-sm">
                        <img src={require('../images/Martin.jpg')} className="d-block w-100 rounded teamfoto" alt="Dr. Martin Marady" />
                        <div className="carousel-caption d-none d-md-block">
                            <h5>Dr. Martin Marady</h5>
                            <p>Facharzt für Augenheilkunde</p>
                        </div>

                    </div>
                    <div className="col-sm">
                        <img src={require('../images/Team.jpg')} className="d-block w-100 rounded shadow" alt="Team Dr. Martin Marady" />
                    </div>
                    <div className="col-sm">
                        <div id="carouselTeamCaptions" className="carousel slide shadow" data-ride="carousel">
                            <ol className="carousel-indicators">
                                <li data-target="#carouselTeamCaptions" data-slide-to="0" className="active"></li>
                                <li data-target="#carouselTeamCaptions" data-slide-to="1"></li>
                                <li data-target="#carouselTeamCaptions" data-slide-to="2"></li>
                                <li data-target="#carouselTeamCaptions" data-slide-to="3"></li>
                                <li data-target="#carouselTeamCaptions" data-slide-to="4"></li>
                                <li data-target="#carouselTeamCaptions" data-slide-to="5"></li>
                                <li data-target="#carouselTeamCaptions" data-slide-to="6"></li>
                            </ol>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img src={require('../images/Punkl.jpeg')} className="d-block w-100 rounded teamfoto" alt="Klaudia Punkl" />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h5>Klaudia Punkl</h5>
                                        <p>Dipl. Ordinationsassitentin</p>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img src={require('../images/Sabine.jpg')} className="d-block w-100 rounded teamfoto" alt="Sabine Dultinger" />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h5>Sabine Dultinger</h5>
                                        <p>Dipl. Ordinations- und Augenarztassitentin</p>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img src={require('../images/Eva.jpg')} className="d-block w-100 rounded teamfoto" alt="Eva Pölzlbauer" />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h5>Eva Pölzlbauer</h5>
                                        <p>Dipl. Ordinations- und Augenarztassistentin und Kosmetikerin</p>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img src={require('../images/Gertrude.jpg')} className="d-block w-100 rounded teamfoto" alt="Gertrude Schimpfhuber" />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h5>Gertrude Schimpfhuber</h5>
                                        <p>Dipl. Ordinations- und Augenarztassitentin</p>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img src={require('../images/Jelena.jpg')} className="d-block w-100 rounded teamfoto" alt="Jelena Velicic" />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h5>Jelena Velicic</h5>
                                        <p>Dipl. Krankenschwester</p>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img src={require('../images/Teresa.jpg')} className="d-block w-100 rounded teamfoto" alt="Teresa Marady" />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h5>Teresa Marady</h5>
                                        <p>Ordninationshilfe</p>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img src={require('../images/Bruckner.jpeg')} className="d-block w-100 rounded teamfoto" alt="Nadja Bruckner" />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h5>Nadja Bruckner</h5>
                                        <p>Dipl. Orthoptistin</p>
                                    </div>
                                </div>
                            </div>
                            <a className="carousel-control-prev" href="#carouselTeamCaptions" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselTeamCaptions" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
