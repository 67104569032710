import React, { Component } from 'react';

export class Kontakt extends Component {
    static displayName = Kontakt.name;
    constructor(props) {
        super(props);
        this.state = {
            formControls: {
                anliegen: '',
                name: '',
                strasse: '',
                ort: '',
                tel: '',
                mail: '',
                problem: false,
                linsen: false,
                termin: false,
                anderes: false,
            },
            information: ''
        };
    }

    changeHandler = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            formControls: {
                ...this.state.formControls,
                [name]: {
                    ...this.state.formControls[name],
                    value
                }
            }
        });
    }

    handleSubmit = event => {
        event.preventDefault();

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                anliegen: this.state.formControls.anliegen.value,
                name: this.state.formControls.name.value,
                strasse: this.state.formControls.strasse.value,
                ort: this.state.formControls.ort.value,
                tel: this.state.formControls.tel.value,
                mail: this.state.formControls.mail.value,
                problem: this.state.formControls.problem.value,
                linsen: this.state.formControls.linsen.value,
                termin: this.state.formControls.termin.value,
                anderes: this.state.formControls.anderes.value,
            })
        };
        fetch(`${process.env.REACT_APP_API_URL}/popup`, requestOptions)
            .then(response => response.text())
            .then(daten => {
                this.setState({
                    information: daten
                });
            });
    }

    render() {
        const gesendet = (this.state.information !== "");
        return (
            <div>

                <div id="KontaktResult" style={{ display: (gesendet ? 'block' : 'none') }}>
                    <div className="alert alert-warning" role="alert" >
                        {this.state.information}
                    </div>
                </div>
                <div id="KontaktForm" style={{ display: (gesendet ? 'none' : 'block') }}>
                    <form onSubmit={this.handleSubmit} id="kform">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm form-group field">
                                    <input id="problem" type="checkbox" name="problem" value={this.state.formControls.problem.value} onChange={this.changeHandler} />&nbsp;
                                <label htmlFor="problem"> Augenproblem</label>
                                </div>
                                <div className="col-sm form-group field">
                                    <input id="linsen" type="checkbox" name="linsen" value={this.state.formControls.linsen.value} onChange={this.changeHandler} />&nbsp;
                                <label htmlFor="linsen"> Kontaktlinsen</label>
                                </div>
                                <div className="col-sm form-group field">
                                    <input id="termin" type="checkbox" name="termin" value={this.state.formControls.termin.value} onChange={this.changeHandler} />&nbsp;
                                <label htmlFor="termin"> Termin</label>
                                </div>
                                <div className="col-sm form-group field">
                                    <input id="anderes" type="checkbox" name="anderes" value={this.state.formControls.anderes.value} onChange={this.changeHandler} />&nbsp;
                                <label htmlFor="anderes"> Anderes</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col form-group field">
                                    <textarea id="anliegen"
                                        rows="5"
                                        name="anliegen"
                                        placeholder="  Ihr Anliegen... "
                                        className="w-100"
                                        value={this.state.formControls.anliegen.value}
                                        onChange={this.changeHandler}
                                    />
                                </div>
                            </div>
                            <h4>Ihre Daten</h4>
                            <div className="row">
                                <div className="col-sm-4 form-group field">
                                    <label htmlFor="name">Vor- u. Nachname: </label>
                                    <input id="name" type="text" name="name" value={this.state.formControls.name.value} onChange={this.changeHandler} />
                                </div>
                                <div className="col-sm-4 form-group field">
                                    <label htmlFor="strasse">Strasse: </label>
                                    <input id="strasse" type="text" name="strasse" value={this.state.formControls.strasse.value} onChange={this.changeHandler} />
                                </div>
                                <div className="ccol-sm-4 form-group field">
                                    <label htmlFor="ort">PLZ u. Ort: </label>
                                    <input id="ort" type="text" name="ort" value={this.state.formControls.ort.value} onChange={this.changeHandler} />
                                </div>
                                <div className="col-sm-4 form-group field">
                                    <label htmlFor="tel">Telefon: </label>
                                    <input id="tel" type="text" name="tel" value={this.state.formControls.tel.value} onChange={this.changeHandler} />
                                </div>
                                <div className="col-sm-4 form-group field">
                                    <label htmlFor="mail">E-Mail: </label>
                                    <input id="mail" type="text" name="mail" value={this.state.formControls.mail.value} onChange={this.changeHandler} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 form-group field">
                                    <input variant="primary" type="submit" value="Anfrage absenden" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
