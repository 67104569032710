import React, { Component } from 'react';

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = { daten: undefined, loading: true };
    }

    componentDidMount() {
        this.populateData();
    }

    static renderDatenDiv(daten) {
        if (daten !== undefined) {
            return (
                <div className="alert alert-warning alert-dismissible fade show" role="alert">
                    <div dangerouslySetInnerHTML={{ __html: daten.text }}></div>
                    <button type="button" className="close" data-dismiss="alert" aria-label="Schließen">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

            );
        }
        else {
            return (<div />);
        }
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Lade...</em></p>
            : Home.renderDatenDiv(this.state.daten);

        return (
            <div>
                {contents}
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm">
                                <h1 className="card-title">Ordination Dr. Martin Marady</h1>
                                <h2 className="card-title">Facharzt für Augenheilkunde und Opto&shy;metrie<br />
                            Alle Kassen und Privat<br />
                        Kontaktlinseninstitut</h2>
                            </div>
                            <div className="col-sm">
                                <div id="carouselOrdiCaptions" className="carousel slide shadow" data-ride="carousel">
                                    <ol className="carousel-indicators">
                                        <li data-target="#carouselOrdiCaptions" data-slide-to="0" className="active"></li>
                                        <li data-target="#carouselOrdiCaptions" data-slide-to="1"></li>
                                        <li data-target="#carouselOrdiCaptions" data-slide-to="2"></li>
                                    </ol>
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <img src={require('../images/Haus01.jpg')} className="d-block w-100 rounded teamfoto" alt="" />
                                            <div className="carousel-caption d-none d-md-block">Eingang
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <img src={require('../images/Haus02.jpg')} className="d-block w-100 rounded teamfoto" alt="" />
                                            <div className="carousel-caption d-none d-md-block">Parkplatzeinfahrt
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <img src={require('../images/Haus03.jpg')} className="d-block w-100 rounded teamfoto" alt="" />
                                            <div className="carousel-caption d-none d-md-block">Burghardgasse 1
                                            </div>
                                        </div>

                                    </div>
                                    <a className="carousel-control-prev" href="#carouselOrdiCaptions" role="button" data-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                    <a className="carousel-control-next" href="#carouselOrdiCaptions" role="button" data-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="container">
                            <div className="row">

                                <div className="col-sm">
                                    <h2>Unser Leitbild</h2>
                                    <h3>Ihre Augenanliegen sind Mittelpunkt unserer Bemühungen</h3>
                                    <p>Unser Team steht ihnen gerne zur Verfügung.</p>
                                    <div>
                                        Wir bieten hochqualitative <a href='leistungen'>Leistungen</a> an:
                                    <ul>
                                            <li>
                                                <a href='leistungen/#Kontaktlinsen'>Kontaktlinsenanpassungen vom Augenarzt</a> stellen eine unserer Spezialitäten dar.
                                            </li>
                                            <li>
                                                Nutzen Sie das breite Angebot der berührungslosen <a href='leistungen/#OCT'>Früherkennung</a> von
                                            <ul>
                                                    <li>Glaukom</li>
                                                    <li>Maculadegeneration</li>
                                                    <li>Netzhautschäden bei Diabetikern.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <a href="ueber/#Team">
                                        <img src={require('../images/Team.jpg')} className="img-fluid rounded shadow" alt="Team Dr. Martin Marady" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm">
                                <h3 className="card-title">Ordinationszeiten:</h3>
                                <p>
                                    Mo, Do 9-12 u 14-17, Di, Fr 8-12<br />
                                    sowie nach telefonischer Vereinbarung
                                </p>
                            </div>
                            <div className="col-sm">
                                <h3 className="card-title">Kontakt:</h3>
                                <p>
                                    <b>Mail:</b> <a href="mailto:ordi@dr-marady.at">ordi@dr-marady.at</a><br />
                                    <b>Telefon:</b> <a href="tel:+43262223344">02622 233 44</a>
                                </p>
                            </div>
                            <div className="col-sm">
                                <h3 className="card-title">Parkplatz:</h3>
                                <p>
                                    Es stehen unseren Patienten 18 Gratis Parkplätze für die Dauer des Besuches vor der Ordination zur Verfügung.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <a href="/leistungen">
                            <button type="button" className="btn btn-outline-secondary btn-lg btn-block" >Leistungen</button>
                        </a>
                    </div>
                </div>

            </div>
        );
    }

    async populateData() {
        
        var url = `${process.env.REACT_APP_API_URL}/popup`;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({ daten: data, loading: false });
    }
}
