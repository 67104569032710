import React, { Component } from 'react';

export class Wartungstarttext extends Component {
    static displayName = Wartungstarttext.name;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            text: '',
            information: ''
        };
    }

    componentDidMount() {
        this.populateData();
    }

    async populateData() {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/popup`);
        const data = await response.json();
        if (data.text !== undefined) {
            this.setState({ text: data.text.split("<br />").join("\r") });
        }
        this.setState({ loading: false });
        this.render();
    }

    changeHandler = event => {
        const target = event.target;
        const value = target.value;

        this.setState({ text: value });
    }

    handleSubmit = event => {
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                text: this.state.text,
            })
        };
        fetch(`${process.env.REACT_APP_API_URL}/WartungStartText`, requestOptions)
            .then(response => response.text())
            .then(daten => {
                this.setState({ information: daten });
            });
    }

    render() {
        const gesendet = (this.state.information !== "");
        return (
            <div>

                <div id="KontaktResult" style={{ display: (gesendet ? 'block' : 'none') }}>
                    <div className="alert alert-warning" role="alert" >
                        {this.state.information}
                    </div>
                </div>
                <div id="KontaktForm" style={{ display: (gesendet ? 'none' : 'block') }}>
                    <form onSubmit={this.handleSubmit}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col form-group field">
                                    <textarea id="text"
                                        rows="5"
                                        name="text"
                                        className="w-100"
                                        value={this.state.text}
                                        onChange={this.changeHandler}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 form-group field">
                                    <input variant="primary" type="submit" value="Anfrage absenden" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    
}
