import React, { Component } from 'react';

export class Ordination extends Component {
    static displayName = Ordination.name;

    render() {
        return (
            <div className="container" id="Ordination">
                <div className="row">
                    <div className="col-sm">
                        <img src={require('../images/Haus01.jpg')} className="d-block w-100 rounded shadow" alt="Ordination" />
                    </div>
                    <div className="col-sm">
                        <h2>Wir sind übersiedelt!</h2>
                        <p>
                            <b>NEUE ADRESSE:</b><br />
                            BURKHARDGASSE 1 /1. Stock<br />
                            (Lift Ebene 2)<br />
                            2700 Wiener Neustadt
                        </p>
                    </div>

                    <div className="col-sm">
                        <img src={require('../images/Ordination02.jpg')} className="d-block w-100 rounded shadow" alt="Team Dr. Martin Marady" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <b>Ordinationszeiten:</b><br />
                        Mo, Do 9-12 u 14-17, Di, Fr 8-12<br />
                        sowie nach telefonischer Vereinbarung
                    </div>
                </div>
            </div>
        );
    }
}
